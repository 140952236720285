.home {
    text-align: center;
    padding: 2rem;
  }
  
  .image-container {
    position: relative;
    width: 100%;
    height: 820px;
    overflow: hidden;
    border-radius: 2rem;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .services {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    flex-wrap: wrap;
  }
  
  .service {
    width: 30%;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #f0f0f0;
  }
  
  .service-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .service-title {
    color: #7295c7;
  }
  
  .service-text {
    color: #475974;
    font-size: 1.2rem;
    line-height: 1.5;
    font-style: italic;
  }
  
  .highlight-section {
    background-color: #333;
    color: #fff;
    padding: 2rem;
    border-radius: 5px;
    margin-bottom: 2rem;
  }
  
  .highlight-title, .highlight-subtitle, .highlight-text {
    font-style: italic;
  }
  
  .portfolio {
    text-align: center;
    padding: 2rem;
  }
  
  .portfolio-title {
    color: #475974;
    font-size: 3rem;
  }
  
  .categories {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    flex-wrap: wrap;
  }
  
  .projects {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .project-image {
    width: 30%;
    margin: 1rem;
  }
  
  .category-button {
    background-color: #7295c7;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, background-image 0.3s;
    background-image: linear-gradient(to right, #7295c7, #475974);
    font-size: 1.5rem;
  }
  
  .category-button.hover {
    background-image: linear-gradient(to right, #475974, #7295c7);
  }
  
  .category-button.selected {
    background-color: #475974;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .services, .projects {
      flex-direction: column;
      align-items: center;
    }
  
    .service, .project-image {
      width: 90%;
    }
  
    .image-container {
      height: 500px;
    }
  
    .service-image {
      height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .category-button {
      font-size: 1.2rem;
    }
  
    .image-container {
      height: 300px;
    }
  
    .service-image {
      height: 150px;
    }
  }
  