.contact {
    text-align: center;
    padding: 2rem;
  }
  
  .contact-title {
    color: #475974;
    font-style: italic;
  }
  
  .form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
  }
  
  .contact-input, .contact-textarea {
    margin: 0.5rem 0;
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-style: italic;
  }
  
  .contact-textarea {
    height: 100px;
  }
  
  .contact-button {
    padding: 0.75rem 1.5rem;
    background-color: #475974;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .log-call {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
  }
  
  .log-call-title {
    color: #475974;
  }
  
  .log-call-label {
    color: #7295c7;
    font-style: italic;
    font-weight: bold;
  }
  
  .date-picker {
    margin-bottom: 1rem;
  }
  
  .log-call-text {
    color: #7295c7;
    font-style: italic;
  }
  
  .map {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    margin: 2rem auto;
  }
  
  .map iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 5px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .form-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-form {
      width: 100%;
      margin-left: 0;
    }
  
    .log-call {
      width: 100%;
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .contact-button {
      font-size: 1rem;
    }
  
    .log-call-title, .log-call-label, .log-call-text {
      font-size: 1rem;
    }
  
    .map {
      height: 300px;
    }
  }
  