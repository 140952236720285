.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #475974; /* Dark blue background */
    color: #fff; /* White text */
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    flex-wrap: wrap; /* Ensure content wraps on small screens */
  }
  
  .footer-text {
    max-width: 25%;
    text-align: left;
    margin-bottom: 1rem; /* Add margin for small screens */
  }
  
  .footer-title {
    font-style: italic;
  }
  
  .footer-links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 5rem;
  }
  
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    margin: 0;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-text,
    .footer-links-container {
      max-width: 100%;
      text-align: center;
      margin-right: 0;
    }
  
    .footer-text {
      margin-bottom: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .footer-links {
      font-size: 1.2rem;
    }
  
    .footer-text,
    .footer-links-container {
      padding: 0 1rem;
    }
  }
  