.header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #fff;
    color: #475974;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .logo-img {
    height: 3rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }
  
  .nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    margin: 0 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    position: relative;
  }
  
  .nav-link {
    color: #475974;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 600;
    letter-spacing: 0.05rem;
    position: relative;
    display: inline-block;
    margin-right: 3rem;
    transition: color 0.3s ease-in-out;
  }
  
  .nav-link-text {
    position: relative;
  }
  
  .nav-link-underline {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #475974;
    transition: width 0.3s ease-in-out;
  }
  
  .nav-link:hover .nav-link-underline,
  .nav-link.selected .nav-link-underline {
    width: 100%;
    left: 0;
    right: auto;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .logo-img {
      margin: 0 0 1rem 0;
    }
  
    .nav {
      flex-direction: column;
      width: 100%;
    }
  
    .nav-item {
      margin: 0.5rem 0;
    }
  
    .nav-link {
      margin-right: 0;
    }
  }
  
  @media (max-width: 480px) {
    .nav-link {
      font-size: 1.2rem;
    }
  
    .nav-item {
      font-size: 1.2rem;
    }
  }
  